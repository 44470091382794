import React from 'react';
import { Link } from 'gatsby';

import ActivityBreadCrumb from '../../components/ActivityBreadCrumbs';
import Layout from '../../components/layout';
import Seo from '../../components/Seo';
import ShareYouThoughts from '../../components/ShareYouThoughts';

const Mission1 = ({ location }) => (
  <Layout page="activities" location={location}>
    <Seo
      manualTags={{
        seoTitle: 'Mission one',
        seoDescription: '',
      }}
    />
    <section className="aq-feature-bg pt-8 pb-8 grey-overlay">
      <ActivityBreadCrumb location={location} />
      <div className="container-fluid">
        <div className="component-wrap">
          <div className="row">
            <div className="col-lg-6 pb-4 pb-lg-0">
              <h1 className="h1-two-line-v2 green mb-12 mb-lg-16 mt-8">
                <span>Mission 1</span>
              </h1>
            </div>
            <div className="col-lg-6 pb-4 pb-lg-0">
              <div
                style={{
                  position: 'absolute',
                  top: '50px',
                  left: '450px',
                  height: '135px',
                  width: '135px',
                  transform: 'rotate(340deg)',
                }}
                className="d-none d-lg-block"
              >
                <img
                  src="/images/Doodles/Stars/Star-2.svg"
                  alt="Illustration of asterisks"
                />
              </div>
              <div
                style={{
                  position: 'absolute',
                  top: '100px',
                  left: '550px',
                  height: '135px',
                  width: '135px',
                  transform: 'rotate(20deg)',
                }}
                className="d-none d-lg-block"
              >
                <img
                  height="100px"
                  src="/images/Doodles/Exclamations/Exclamation-7.svg"
                  alt="Illustration of punctuation"
                />
              </div>
            </div>
          </div>
          {/* Row 1 */}
          <div className="row px-xl-8 gx-3 pb-5">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>We are the</span>
                  <br />
                  <span>Aquanauts!</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionOne/Activity-Drawing.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-1/we-are-the-aquanauts"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>All aboard</span>
                  <br />
                  <span>the Aquabus</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionOne/Activity-Aquabus_V2.svg)`,
                      right: '-18px',
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-1/all-aboard-the-aquabus"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Speedy shower</span>
                  <br />
                  <span>challenge</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionOne/Activity-Speedy-Shower.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-1/speedy-shower-challenge"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Row 2 */}
          <div className="row px-xl-8 gx-3 pb-5">
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Spot the</span>
                  <br />
                  <span>difference</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionOne/Activity-Spot-the-difference.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-1/spot-the-difference"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Water saving</span>
                  <br />
                  <span>Game</span>
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionOne/Activity-Board-Game.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-1/water-saving-game"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4 mb-lg-0">
              <div className="aq-card h-100 small p-3 pb-4 bg-turquoise d-flex flex-column">
                <h3 className="small-v2 teal">
                  <span>Quiz!</span>
                  <br />
                  <span />
                </h3>
                <div className="image-position">
                  <div
                    className="image"
                    style={{
                      backgroundImage: `url(/images/Activities/MissionOne/Activity-Quiz.svg)`,
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <Link
                    className="btn btn-pink"
                    to="/activities/mission-1/quiz"
                  >
                    Let's go!
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ShareYouThoughts />
  </Layout>
);

export default Mission1;
